import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DevexModuleModule } from '../devex-module.module';
import { SpinnerOverlayComponent } from './common/spinner-overlay/spinner-overlay.component';
import { ConfirmContinueComponent } from './common/confirm-continue/confirm-continue.component';


@NgModule({
  declarations: [
    SpinnerOverlayComponent,
    ConfirmContinueComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DevexModuleModule
  ],
  exports: [
    SpinnerOverlayComponent
  ]
})
export class ComponentsModule { }
