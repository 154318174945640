
export const environment = {
  production: false,
  maintenance: false,
  apiURL: "/api",
  ClientAppSettings: {
    gaBarUrl: 'https://dev.gabar.org'
  },
  AuthSettings: {
    cleAPI: {
      endpoint: 'https://dev.portals.georgiabar.org/api/cle',
      scopes: {
          read: ['api://5e19a916-9eb9-427a-8a85-5880605739b9/CLE.SponsorPortal.Access'],
          write: ['api://5e19a916-9eb9-427a-8a85-5880605739b9/CLE.SponsorPortal.Access'],
      },
    },
    newCourseAPI: {
      endpoint: 'https://dev.portals.georgiabar.org/api/cle/NewCourse',
      scopes: {
          read: ['api://5e19a916-9eb9-427a-8a85-5880605739b9/CLE.SponsorPortal.Access'],
          write: ['api://5e19a916-9eb9-427a-8a85-5880605739b9/CLE.SponsorPortal.Access'],
      },
    },
    newCoursesAPI: {
      endpoint: 'https://dev.portals.georgiabar.org/api/cle/newcourses',
      scopes: {
          read: ['api://5e19a916-9eb9-427a-8a85-5880605739b9/CLE.SponsorPortal.Access'],
          write: ['api://5e19a916-9eb9-427a-8a85-5880605739b9/CLE.SponsorPortal.Access'],
      },
    },
  },
};
