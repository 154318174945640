import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EnvironmentSettingsService } from '../api/environment-settings.service';

@Injectable({
  providedIn: 'root'
})
export class AppMaintGuard  {

  constructor(
    private router: Router,
    private maintService: EnvironmentSettingsService
  ) { }

  canActivate(
    _next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    // if (environment.maintenance) {
    //   this.router.navigate(['/maintenance']);
    //   return false;
    // }

     //return true;

    return this.maintService.getMaintenanceMode()
      .pipe(take(1),
        mergeMap(result => {
          if (result) {
            this.router.navigate(['/maintenance']);
            return of(false);
          }
          return of(true);
        })
      );

  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    //return true;
    return this.maintService.getMaintenanceMode()
    .pipe(take(1),
      mergeMap(result => {
        if (result) {
          this.router.navigate(['/maintenance']);
          return of(false);
        }
        return of(true);
      })
    );

  }

}
