import { NgModule } from '@angular/core';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxTabPanelModule } from 'devextreme-angular/ui/tab-panel';
import { DxFileUploaderModule } from 'devextreme-angular/ui/file-uploader';

@NgModule({
  declarations: [],
  exports: [
    DxButtonModule,
    DxDataGridModule,
    DxTabPanelModule,
    DxFileUploaderModule,
  ]
})
export class DevexModuleModule { }
