
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from './components/components.module';
import { SharedMaterialModule } from './shared-material.module';
import { SiteFooterComponent } from './layout/site-footer/site-footer.component';
import { SiteHeaderComponent } from './layout/site-header/site-header.component';
// import { SideNavComponent } from './layout/side-nav/side-nav.component';
// import { FileDatabaseUtility } from './classes/file-database.utility';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DevexModuleModule } from './devex-module.module';

@NgModule({
  declarations: [
    SiteFooterComponent,
    SiteHeaderComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ComponentsModule,
    FontAwesomeModule,
    SharedMaterialModule,
    DevexModuleModule
  ],
  exports: [
    SiteFooterComponent,
    SiteHeaderComponent,
    DevexModuleModule,
    SharedMaterialModule
  ]
})
export class SharedModule { }
