import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentSettingsService {


  constructor(private http: HttpClient) { }

  public getMaintenanceMode(): Observable<boolean> {
    return this.http.get<boolean>(environment.apiURL + '/EnvironmentInfo/MaintenanceMode');
  }

  public getEFileMessaage(): Observable<string> {
    return this.http.get(environment.apiURL + '/EnvironmentInfo/EFileMessaage', { observe: 'body', responseType: 'text' as 'text'});
  }

  public getIPAddress()
  {
    return this.http.get("https://api.ipify.org/?format=json");
  }
}
