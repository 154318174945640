<app-site-header headerText='' hideLogin="true"></app-site-header>

<div class="home-container" style="word-wrap: break-word;">
  <div class="site-link home-col-2" (click)="gotoCLEPortal()">
    Georgia Continuing Legal Education Sponsor Portal (CLEPortal)
  </div>
  <div class="site-link home-col-4" (click)="gotoEFile()">
    State Disciplinary Board E-Filing Portal
  </div>
</div>
