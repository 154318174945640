<div class="flex-column">
  <p>{{getCaption()}}</p>
  <div class="flex-column">
    <dx-button style="float:left; margin: 10px 12px;"
    id="button"
    text="Continue"
    type="default" item-width="50px" (click)="continue()">
  </dx-button>
  <dx-button style="float:left; margin: 10px 12px;"
    stylingMode="outlined"
    id="button"
    text="Cancel"
    type="default" item-width="50px" (click)="cancel()">
  </dx-button>
  </div>
</div>


