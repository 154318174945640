import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';

import { SpinnerOverlayService } from 'src/app/_core/services/common/spinner-overlay.service';
import { AuthService } from 'src/app/_core/services/auth.service';

@Component({
  selector: 'app-site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.scss']
})
export class SiteHeaderComponent implements OnInit, OnDestroy {
  @Input() headerText: string = 'Attorney Portal';
  @Input() hideLogin: boolean = false;

  faUserCircle = faUserCircle;

  private subs = new SubSink();

  public firstName: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public isAuthenticated: Observable<boolean>;

  userRole: string;
  userData: any;

  constructor(
    private router: Router,
    private authService: AuthService,
    // private spinnerService: SpinnerOverlayService,
  ) {
    console.log("SiteHeaderComponent.constructor")
   }

  ngOnInit(): void {
    this.isAuthenticated = this.authService.isAuthenticated();
    //this.userName = this.authorizeService.getUser().pipe(map(u => u && u.firstName));

    // this.subs.add(this.authService.getUser()
    //   .subscribe(user => {
    //     if (user && user.firstName) {
    //       this.firstName.next(user.firstName);
    //     }
    //     //this.setLink(role);
    //   }));

  }

  ngOnDestroy() {
    // this.spinnerService.hide();
    this.subs.unsubscribe();
  }

  login() {
    // this.spinnerService.show();
    this.router.navigate(['./authentication/login']);
  }

  logout() {
    // this.spinnerService.show();
    this.router.navigate(['./authentication/logout']);
  }

}
