<div class="header-container">
  <div class="header-col-1">
    <img src="assets/images/gbar-logo_66897.png" />
  </div>
  <div  class="sb-banner-header header-col-2">{{headerText}}</div>
  <div class="header-col-3">
    <div *ngIf="!hideLogin" class="flex-row">
      <fa-icon [icon]="faUserCircle" class="sb-base-color"></fa-icon>
      <div *ngIf="isAuthenticated | async" class="flex-row">
        <button mat-button [matMenuTriggerFor]="menu">Welcome, {{firstName | async}}</button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="logout()">Logoff</button>
          <!-- <button mat-menu-item>Item 2</button> -->
        </mat-menu>
      </div>
      <h4 *ngIf="!(isAuthenticated | async)" (click)="login()" style="cursor: pointer; margin-bottom: 0;">Log In</h4>
    </div>
  </div>
</div>
