import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { AppMaintGuard } from './_core/guards/app-maint.guard';
import { MsalRedirectComponent } from '@azure/msal-angular';

const routes: Routes = [
  // {
  //   path: '', pathMatch: "full",
  //   redirectTo: 'ogc'
  // },
  {
    path: 'cle', loadChildren: () => import('./CLE/cle.module').then(m => m.CleModule),
    canLoad: [AppMaintGuard],
    //canActivate: [AuthorizeGuard],
  },
  {
    path: 'prohac', loadChildren: () => import('./ProHac/pro-hac.module').then(m => m.ProHacModule),
    canLoad: [AppMaintGuard],
    //canActivate: [AuthorizeGuard],
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent
  },
  {
    path: 'misc', loadChildren: () => import('./Misc/misc.module').then(m => m.MiscModule),
    canLoad: [AppMaintGuard],
  },
  {
    path: 'public', loadChildren: () => import('./public/public.module').then(m => m.PublicModule),
    canLoad: [AppMaintGuard],
  },
  // {
  //   path: 'home',
  //   component: HomeComponent
  // }
      {
        // Needed for handling redirect after login
        path: 'auth',
        component: MsalRedirectComponent
    },
  {
    path: '',
    pathMatch: "full",
    redirectTo: 'cle'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
